<template>
  <v-container>
    <div class="text-h1">User Admin Portal</div>
    <div class="text-body 01">
      Select the new Role for the User and then click save in that row! I know its a little bit ugly and pfusch but this
      page should never been seen by anyone... ;)
    </div>
    <v-alert type="success" v-if="showAlert">Updated Role</v-alert>
    <v-list>
      <v-list-item v-for="user in users" :key="user.id">
        <v-list-item-avatar>
          <v-img :src="user.googlePicture"></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-row align="center" justify="center">
            <v-col>
              <v-list-item-title v-html="user.fullname"></v-list-item-title>
            </v-col>
            <v-col>
              <v-select v-model="user.role" :items="roles" label="Role" required />
            </v-col>
            <v-col>
              <v-btn @click="updateUser(user)" color="primary">Save</v-btn>
            </v-col>
          </v-row>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-card v-for="user in users" :key="user.id">
      <v-row>
        <v-col>
          <div class="text-overline"></div>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import { Roles, UpdateUser } from '@graphql/Users.gql'

export default {
  name: 'UserPage',
  apollo: {
    users: {
      query: Roles
    }
  },
  data: () => ({
    roles: [
      { text: 'User', value: 'USER' },
      { text: 'Moderator', value: 'MODERATOR' },
      { text: 'Admin', value: 'ADMIN' }
    ],
    showAlert: false
  }),
  methods: {
    updateUser(user) {
      const variables = {
        role: user.role,
        userid: user.id
      }
      this.$apollo
        .mutate({ mutation: UpdateUser, variables })
        .then(() => {
          this.showAlert = true
          setTimeout(() => {
            this.showAlert
          }, 3000)
        })
        .catch((res) => {
          this.$store.commit('openErrorDialog', res)
        })
    }
  }
}
</script>
